* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #040127;
  font-family: sans-serif;
}

.flex-container {
  display: flex;
  height: 400px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
}

.question-area {
  /* background: red; */
  width: 50%;
  color: white;
  text-align: center;
  padding: 2rem 1rem;
  border-radius: 1rem;
  border: 5px solid #ffd700;
  line-height: initial;
  margin-top: -3.5rem;
  background: #040127;
}

.answer-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 7rem 7rem;
  gap: 3rem;
  margin-top: 6rem;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

.answer {
  padding: 1rem 7rem;
  border-radius: 2rem;
  border: 3px solid white;
  text-transform: capitalize;
  transition: all;
  cursor: pointer;
  color: white;
  text-align: center;
}

.correctAns {
  background-color: rgb(19, 163, 19);
}

.wrongAns {
  background-color: red;
}

.result {
  color: white;
  text-align: center;
  font-size: 2rem;
}

.timer {
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  overflow: hidden;
  padding-top: 1rem;
  text-align: center;
  color: black;
  background-color: #ffd700;
}

.game-over-msg-container {
  text-align: center;
  margin-top: 25rem;
}

.time-ran-out-msg {
  color: white;
  font-weight: bold;
}

.game-over-btn-container {
  text-align: center;
}

.btn {
  margin-top: 2rem;
  padding: 0.9rem 3rem;
  background-color: #1c1566;
  font-size: 1.5rem;
  border: 5px solid #ffd700;
  border-radius: 2rem;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
}
@media (hover: hover) {
  .answer:not(.wrongAns):hover {
    background-color: rgb(19, 163, 19);
  }
}
@media (max-width: 768px) {
  .flex-container {
    height: 200px;
  }
  .answer-grid-container {
    grid-template-columns: none;
    grid-template-rows: none;
    gap: 1rem;
    margin-top: 3rem;
  }

  .answer:not(.wrongAns):hover {
    cursor: pointer;
  }

  .question-area {
    width: 90%;
    font-size: 1.2rem;
  }
  .answer {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .flex-container {
    height: 200px;
  }
  .answer-grid-container {
    grid-template-columns: none;
    grid-template-rows: none;
    margin-left: 0rem;
    padding: 0rem;
    gap: 1rem;
    margin-top: 1rem;
  }

  .answer:not(.wrongAns):hover {
    cursor: pointer;
  }

  .question-area {
    width: 90%;
    font-size: 1rem;
  }

  .answer {
    width: 85%;
    margin: 0 auto;
    text-align: left;
    font-size: 0.7rem;
  }
}
